.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  color: black;
}
.products-shop > .MuiListItemText-primary {
  font-size: 12px;
  font-weight: bold;
}
.products-source > .MuiFormControlLabel-label {
  font-size: 15px;
}

.confirm > .MuiCheckbox-colorSecondary.Mui-checked {
  color: gray;
}

.verified > .MuiCheckbox-colorSecondary.Mui-checked {
  color: green;
}
.display > .MuiCheckbox-colorSecondary.Mui-checked {
  color: green;
}

.prepay > .MuiCheckbox-colorSecondary.Mui-checked {
  color: #167bff;
}

.MuiInputBase-input.Mui-disabled {
  color: grey;
}

#complex-autoselect-label {
  color: #000;
  font-style: italic;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

.flash {
  animation: glowing 1300ms infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alert-title {
  font-size: 18px;
}

a {
  text-decoration: none;
}
